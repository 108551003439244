





































import {Component, Vue} from "vue-property-decorator";
import {CategoryType} from "@/models/category/CategoryType";
import {OptionItem} from "@/models/category/category";
import map from "lodash/map";
import debounce from "lodash/debounce";
import {SearchResult} from "@/models/SearchResult";
import {Suggestion} from "@/models/Suggestion";
import {CategoryService} from "@/store/category/CategoryService";
import vSelect from "vue-select";
import {CustomerService} from "@/services/customer/CustomerService";
import {ActionResult} from "@/models/ActionResult";

@Component({
    name: "CustomerQuickUpdate",
    components: {
        "v-select": vSelect,
    },
})

export default class CustomerQuickUpdate extends Vue {
    title: string = "";
    isShow: boolean = false;
    type: number = CategoryType.CustomerResources;
    placeHolder: string = "";
    listCategory: any[] = [];
    categorySelected: any = null;
    debounce: any;
    label: string = "";
    listCustomerIds: string[] = [];

    private categoryService = new CategoryService();
    private customerService = new CustomerService();

    show(type: number, customerIds: string[]) {
        this.isShow = true;
        this.categorySelected = null;
        this.listCategory = [];
        this.title = `Thay đổi: ${new OptionItem(type).name.toLowerCase()}`;
        this.placeHolder = `Chọn ${new OptionItem(type).name.toLowerCase()}`;
        this.label = new OptionItem(type).name;
        this.type = type;
        this.listCustomerIds = customerIds;
    }

    onCustomerUpdated(value: any) {
    }


    accept() {
        if (!this.categorySelected || !this.listCustomerIds || this.listCustomerIds.length === 0) {
            this.$vs.notify({
                color: "danger",
                title: "Thông báo",
                text: `Vui lòng chọn ${new OptionItem(this.type).name.toLowerCase()}`,
            })
        } else {
            switch (this.type) {
                case CategoryType.CustomerResources:
                    this.customerService.updateCustomerResources(this.listCustomerIds, this.categorySelected.id,
                        this.categorySelected.name)
                        .then((result: ActionResult<number>) => {
                            if (result.code > 0) {
                                this.$emit("onSaveSuccess", this.categorySelected);
                                this.isShow = false;
                            }
                            this.$vs.notify({
                                color: result.code > 0 ? "success" : "danger",
                                title: "Thông báo",
                                text: result.message,
                            })
                        }).catch((e: any) => {
                        this.$vs.notify({
                            color: "danger",
                            title: "Thông báo",
                            text: e.message,
                        })
                    })
                    break;
                case CategoryType.CustomerClassify:
                    this.customerService.updateClassifies(this.listCustomerIds, this.categorySelected.id,
                        this.categorySelected.name)
                        .then((result: ActionResult<number>) => {
                            if (result.code > 0) {
                                this.$emit("onSaveSuccess", this.categorySelected);
                                this.isShow = false;
                            }
                            this.$vs.notify({
                                color: result.code > 0 ? "success" : "danger",
                                title: "Thông báo",
                                text: result.message,
                            })
                        }).catch((e: any) => {
                        this.$vs.notify({
                            color: "danger",
                            title: "Thông báo",
                            text: e.message,
                        })
                    })
                    break;

                case CategoryType.CustomerSalesStrategy:
                    this.customerService.updateSalesStrategies(this.listCustomerIds, this.categorySelected.id,
                        this.categorySelected.name)
                        .then((result: ActionResult<number>) => {
                            this.$vs.notify({
                                color: result.code > 0 ? "success" : "danger",
                                title: "Thông báo",
                                text: result.message,
                            })
                            if (result.code > 0) {
                                this.$emit("onSaveSuccess", this.categorySelected);
                                this.isShow = false;
                            }
                        }).catch((e: any) => {
                        this.$vs.notify({
                            color: "danger",
                            title: "Thông báo",
                            text: e.message,
                        })
                    })
                    break;
                default:
                    break;
            }
        }
    }

    selectValue(value: any) {
        this.categorySelected = value;
    }

    focus(search: string) {
        if (!this.listCategory || this.listCategory.length === 0) {
            this.search("", null);
        }
    }

    fetchOptions(search: string, loading: any) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.debounce = debounce(() => {
            this.search(search, loading);
        }, 500);

        this.debounce();
    }

    search(search: string, loading: any) {
        if (loading) {
            loading(true);
        }
        this.categoryService.suggestions(search, this.type)
            .finally(() => {
                if (loading) {
                    loading(false);
                }
            }).then((result: SearchResult<Suggestion>) => {
            if (result.code > 0) {
                this.listCategory = map(result.items, (item: any) => {
                    return {
                        id: item.id,
                        name: item.name,
                        image: item.image ? item.image : item.avatar,
                        data: item,
                    }
                });

            }
        })
    }
}
